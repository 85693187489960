import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import ListIcon from '@mui/icons-material/List';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import { DecryptedDataContext } from '../context/DecryptedDataContext';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import MyNote from './MyNote';
import MyFilteredNotes from './MyFilteredNotes';
import MyExport from './MyExport';
import MyImport from './MyImport';
import ReactQuill from 'react-quill';
import Delta from 'quill-delta';
import '../theme/ReactQuillStyles.css';
import { qToolbarOptions } from '../theme/quillToolbarOptions'
import { useTheme } from '@mui/material/styles';

export const STypography = styled(Typography)(({theme}) => ({
  backgroundColor: theme.palette.divider,
  whiteSpace: 'pre-wrap',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

export const SPaper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2),
}));

const SContainer = styled('div')(({ isGridView }) => ({
  display: 'flex',
  flexDirection: isGridView ? 'row' : 'column', // Adjust the flex direction based on the view mode
  flexWrap: 'wrap',
  gap: '1rem',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

const SToolbar = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center', // This will center-align items vertically
  gap: '2px',
  flexWrap: 'wrap',
}));

export const BorderTextField = styled(TextField)(({ theme }) => ({
  // Style the border and outline
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.secondary, // Default border color
    },
    '&:hover fieldset': {
      borderColor: theme.palette.secondary, // Border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary, // Change border color when focused
      borderWidth: '2px',
    },
  },
  // Add more styles here as necessary
}));

const circleStyle = {
  borderRadius: '50%', // Makes the background circular
  width: 40, // Width of the circle
  height: 40, // Height of the circle
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'primary.main', // Circle color
  color: 'white', // Icon color
};

const MyNotes = ({ onLoad, dKey, onUpdate, loadExample, onExampleLoaded }) => {
  const [newNote, setNewNote] = useState('');
  const { decryptedData, setDecryptedData } = useContext(DecryptedDataContext);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const parsedData = JSON.parse(decryptedData);
  const notes = parsedData.notes || [];
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [checkedNotes, setCheckedNotes] = useState(new Set());
  const [isCheckEnabled, setIsCheckEnabled] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isTreeView, setIsTreeView] = useState(false);
  const theme = useTheme();
  const quillRef = useRef(null);
  const [expanded, setExpanded] = useState(() => {
    const savedExpanded = localStorage.getItem('isExpanded');
    return savedExpanded !== null ? JSON.parse(savedExpanded) : false;
  });
  const [loading, setLoading] = useState(false);

  const sortedNotes = useMemo(() => {
    return notes.sort((a, b) => {
      const timeA = a.updated || a.created || 0;
      const timeB = b.updated || b.created || 0;
      return timeB - timeA;
    });
  }, [decryptedData]); // Assuming notes comes directly from decryptedData

    // Filter checked notes
    const checkedNotesArray = sortedNotes.filter(note => checkedNotes.has(note.id));

    // New state for tracking all labels
    const [allLabels, setAllLabels] = useState(() => {
      const labels = new Set();
      notes.forEach(note => {
        if (note.labels) {
          note.labels.forEach(label => labels.add(label));
        }
      });
      return Array.from(labels);
    });
    // State to keep track of view mode (list/grid)
    const [isGridView, setIsGridView] = useState(() => {
      // Initialize from localStorage or default to false (list view)
      return JSON.parse(localStorage.getItem('isGridView')) || false;
    });
    useEffect(() => {
      onLoad(sortedNotes.length);
    }, [onLoad, sortedNotes.length]);

    useEffect(() => {
      if (quillRef.current) {
        const quill = quillRef.current.getEditor(); // Accessing the Quill editor instance
        // Add a custom clipboard matcher
        quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
          const newDelta = new Delta();
          delta.ops.forEach(op => {
            if (!(op.insert && typeof op.insert === 'object' && op.insert.image)) {
              newDelta.push(op); // Add non-image ops to the new Delta
            }
          });
          return newDelta;
        });
      }
    }, []);

    useEffect(() => {
      localStorage.setItem('isExpanded', JSON.stringify(expanded));
    }, [expanded]);
  
    // Update localStorage whenever the view mode changes
    useEffect(() => {
      localStorage.setItem('isGridView', JSON.stringify(isGridView));
    }, [isGridView]);

    // Reset checkedNotes when isCheckEnabled is set to false
    useEffect(() => {
      if (!isCheckEnabled) {
        setCheckedNotes(new Set());
        setIsCheckAll(false);
      }
    }, [isCheckEnabled]);

  const handleFilteredNotesChange = (newFilteredNotes) => {
    setFilteredNotes(newFilteredNotes);
  };

  const updateAndEncrypt = (parsedData) => {
    const updatedData = JSON.stringify(parsedData);
    setDecryptedData(updatedData);
    const encryptedData = CryptoJS.AES.encrypt(updatedData, dKey).toString();
    onUpdate(encryptedData);
  };


  const handleAddLabelToNote = (noteId, newLabel) => {
    const parsedData = JSON.parse(decryptedData);
    const note = parsedData.notes.find(n => n.id === noteId);

    if (note) {
      if (!note.labels) {
        note.labels = [];
      }

      if (!note.labels.includes(newLabel)) {
        note.labels.push(newLabel);
        updateAndEncrypt(parsedData);
      } else {
        setSnackbarMessage(`Label "${newLabel}" exists`);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    } else {
      // Handle error: note not found
      setSnackbarMessage('Note not found');
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }

    if (!allLabels.includes(newLabel)) {
      setAllLabels(prevLabels => [...prevLabels, newLabel]);
    }
  };

  // Handle adding a new note here
  const handleAddNote = () => {
    if (!newNote.trim()) {
      setSnackbarMessage('Note cannot be empty. Type in something');
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    const parsedData = JSON.parse(decryptedData);
    // Initialize parsedData.notes if it is undefined
    if (!parsedData.notes) {
      parsedData.notes = [];
    }

    // Adding a single new note
    parsedData.notes.push({ id: uuidv4(), text: newNote, created: Date.now(), updated: Date.now() });
    updateData(parsedData);
    setNewNote('');
  };

  const handleImportNotes = (importedNotes) => {
    const parsedData = JSON.parse(decryptedData);

    if (!parsedData.notes) {
      parsedData.notes = [];
    }

    let updatedLabels = new Set(allLabels);
    importedNotes.forEach(importedNote => {
      const noteWithImportedFlag = { ...importedNote, imported: true, updated: Date.now() };

      if (!parsedData.notes.some(note => note.id === importedNote.id)) {
        parsedData.notes.push(noteWithImportedFlag);

        // Add new labels from imported notes to updatedLabels
        if (importedNote.labels) {
          importedNote.labels.forEach(label => updatedLabels.add(label));
        }
      }
    });

    // Update the allLabels state
    setAllLabels(Array.from(updatedLabels));

    updateData(parsedData);
};

  const updateData = (parsedData) => {
    const updatedData = JSON.stringify(parsedData);
    setDecryptedData(updatedData);
    const encryptedData = CryptoJS.AES.encrypt(updatedData, dKey).toString();
    onUpdate(encryptedData);
  };

  // Handle editing a selected note here
  const handleEditNote = (id, updatedText, isTimeUpdated) => {
    const parsedData = JSON.parse(decryptedData);
    const noteToEdit = parsedData.notes.find(note => note.id === id);

    if (noteToEdit) {
      noteToEdit.text = updatedText;
      if(isTimeUpdated) {
        noteToEdit.updated = Date.now();
      }
      if(noteToEdit.imported) {
        noteToEdit.imported = false;
      }
    }
    updateAndEncrypt(parsedData);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (idOrIds) => {
    setNoteToDelete(idOrIds);
    setOpenDeleteDialog(true);
  };

  // Function to toggle expand mode
  const toggleExpandMode = async () => {
    setLoading(true);  // Start loading
    setExpanded((prevExpanded) => !prevExpanded);
    
    // Simulate lengthy operation (you can replace this with actual code)
    await new Promise(resolve => setTimeout(resolve, 2000)); 
    
    setLoading(false); // End loading
  };

  // Function to toggle view mode
  const toggleViewMode = async () => {
    setLoading(true);  // Start loading
    setIsGridView((prevIsGridView) => !prevIsGridView);
    await new Promise(resolve => setTimeout(resolve, 2000)); 
    
    setLoading(false); // End loading
  };

  const toggleViewModeBKP = () => {
    setIsGridView((prevIsGridView) => !prevIsGridView);
  };

  // Function to toggle check mode
  const toggleCheckMode = () => {
    setIsCheckEnabled((prevIsCheckEnabled) => !prevIsCheckEnabled);
    setIsCheckAll(false);
  };

  // Toggle check all notes
  const toggleCheckAll = () => {
    setIsCheckAll(prevIsCheckAll => {
      const newStatus = !prevIsCheckAll;
      if (newStatus) {
        setCheckedNotes(new Set(sortedNotes.map(note => note.id)));
      } else {
        setCheckedNotes(new Set());
      }
      return newStatus;
    });
  };

  const onLabelDelete = (noteId, updatedLabels) => {
    const updatedNotes = notes.map(n => {
      if (n.id === noteId) {
        return { ...n, labels: updatedLabels };
      }
      return n;
    });
    // Update the state and/or context with this new notes array
    updateAndEncrypt({ ...parsedData, notes: updatedNotes });
  };

  const handleOnImportSuccess = (data) => {
    handleImportNotes(data);
  };

  const handleOnImportError = () => {
    setSnackbarMessage("Import Error. The data is bad or the Key is invalid");
    setSnackbarSeverity("error");
    setOpenSnackbar(true);
  };

  const handleCheckUpdated = (noteId, isChecked) => {
    setCheckedNotes(prevCheckedNotes => {
      const updatedCheckedNotes = new Set(prevCheckedNotes);
      if (isChecked) {
        updatedCheckedNotes.add(noteId);
      } else {
        updatedCheckedNotes.delete(noteId);
      }
      return updatedCheckedNotes;
    });
  };

  const handleDeleteNotes = (noteIds) => {
    const parsedData = JSON.parse(decryptedData);
    parsedData.notes = parsedData.notes.filter(note => !noteIds.includes(note.id));
    updateAndEncrypt(parsedData);
    // Clear checkedNotes state
    setCheckedNotes(new Set());
    setIsCheckAll(false); // Also reset the check all state if you have a "Select All" feature
  };

  // Handle deleting a selected note here
  const handleDeleteNote = (id) => {
    const parsedData = JSON.parse(decryptedData);
    parsedData.notes = parsedData.notes.filter(note => note.id !== id);
    updateAndEncrypt(parsedData);

    // Update checkedNotes if the deleted note was selected
    if (checkedNotes.has(id)) {
      const updatedCheckedNotes = new Set(checkedNotes);
      updatedCheckedNotes.delete(id);
      setCheckedNotes(updatedCheckedNotes);
    }
  };

  const onQuillChange = e => {
    setNewNote(e);
  };  

  return (
    <div>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
        <SPaper elevation={3}>
        <ReactQuill 
          ref={quillRef} // Set the ref
          theme="snow"
          scrollingContainer="body"
          value={newNote}
          onChange={onQuillChange}
          modules={{ toolbar: qToolbarOptions }}
          className={`custom-quill-${localStorage.getItem('theme')}`}
        />
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '16px 0' }}>
              <Button 
                variant="contained" 
                color="primary"
                onClick={handleAddNote}
              >
                Add Note
              </Button>
              </div>
        <SToolbar>
          <MyFilteredNotes notes={sortedNotes} onFilteredNotesChange={handleFilteredNotesChange} />
                <FormControlLabel
                control={
                  <Switch
                    checked={expanded}
                    onChange={toggleExpandMode}
                    name="viewExpandedSwitch"
                    color="primary"
                    sx={{ display: 'none' }} // This hides the switch
                    disabled={loading} // Disable while loading
                  />
                }
                label={
                  expanded ? 
                    <ExpandLessIcon sx={{ verticalAlign: 'middle', color: loading ? 'primary.disabled' : 'primary.main' }} /> : 
                    <ExpandMoreIcon sx={{ verticalAlign: 'middle', color: loading ? 'primary.disabled' : 'primary.main' }} />
                }
                labelPlacement="end"
                sx={{marginLeft: '10px'}}
              />

          <FormControlLabel
                control={
                  <Switch
                    checked={isGridView}
                    onChange={toggleViewMode}
                    name="viewModeSwitch"
                    color="primary"
                    sx={{ display: 'none' }} // This hides the switch
                    disabled={loading} // Disable while loading
                  />
                }
                label={
                  isGridView ? 
                    <ViewModuleIcon sx={{ verticalAlign: 'middle', color: loading ? 'primary.disabled' : 'primary.main' }} /> : 
                    <ViewListIcon sx={{ verticalAlign: 'middle', color: loading ? 'primary.disabled' : 'primary.main' }} />
                }
                labelPlacement="end"
                sx={{marginLeft: '10px'}}
              />
            <FormControlLabel
                control={
                  <Switch
                    checked={isCheckEnabled}
                    onChange={toggleCheckMode}
                    name="checkModeSwitch"
                    color="primary"
                    sx={{ display: 'none' }} // This hides the switch
                  />
                }
                label={
                  isCheckEnabled ? 
                    <CheckBoxIcon sx={{ verticalAlign: 'middle', color: 'primary.main' }} /> : 
                    <CheckBoxOutlineBlankIcon sx={{ verticalAlign: 'middle', color: 'primary.main' }} />
                }
                labelPlacement="end"
                sx={{marginLeft: '10px'}}
              />
              <MyImport onSuccess={handleOnImportSuccess} onError={handleOnImportError} loadExample={loadExample} onSuccessExampleLoaded={() => { onExampleLoaded(); }} />
        </SToolbar>
        </SPaper>
      <SContainer isGridView={isGridView}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2) }}>
          {isCheckEnabled && (
            <>
        <FormControlLabel
        control={
          <Switch
            checked={isCheckAll}
            onChange={toggleCheckAll}
            name="checkAllModeSwitch"
            color="primary"
            sx={{ display: 'none' }} // This hides the switch
          />
        }
        label={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {isCheckAll ? 
            <Box sx={circleStyle}><ChecklistIcon /></Box> : 
            <Box sx={circleStyle}><ListIcon /></Box>}
        </Box>
        }
        labelPlacement="left"
        sx={{marginLeft: '10px'}}
            />
          <MyExport profile={parsedData.user} mydataArray={checkedNotesArray} disabled={!checkedNotesArray.length>0} dKey={dKey} />
          <Divider 
            orientation="vertical" 
            flexItem 
            sx={{ mx: 2, my: 1, height: 'auto', borderColor: theme => theme.palette.neutral[500] }}
        />
          <Button 
            onClick={() => handleOpenDeleteDialog(Array.from(checkedNotes))}
            disabled={checkedNotes.size === 0}
          >
            Delete Selected
          </Button>
          </>
        )}
        </Box>
        {filteredNotes.map((note) => (
        <MyNote
          key={note.id}
          note={note}
          isCheckEnabled={isCheckEnabled}
          isChecked={checkedNotes.has(note.id)}
          isExpanded={expanded}
          onCheckUpdated={handleCheckUpdated}
          labels={allLabels}
          onSave={handleEditNote}
          onDelete={handleOpenDeleteDialog}
          onAddLabelToNote={handleAddLabelToNote}
          onLabelDelete={onLabelDelete}
          isGridView={isGridView}
        />
        ))}

        {loading && (
          <Box 
            sx={{ 
              position: 'fixed', 
              top: '50%', 
              left: '50%', 
              transform: 'translate(-50%, -50%)', 
              zIndex: 1500, 
              bgcolor: 'rgba(55, 65, 81, 0.8)', // cardBody.main with 0.8 transparency
              borderColor: '#1F2937', // neutral[800] for a slightly darker border
              borderWidth: '1px',
              borderStyle: 'solid',
              padding: theme.spacing(2), 
              borderRadius: theme.shape.borderRadius,
            }}
          >
          <Typography variant="body2" sx={{ marginBottom: theme.spacing(1) }}>Updating View...</Typography>
          <LinearProgress />
        </Box>
        )}

      </SContainer>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Array.isArray(noteToDelete)
              ? `Are you sure you want to delete ${noteToDelete.length} note${noteToDelete.length>1 ? "s" : ""}?`
              : "Are you sure you want to delete this note?"}
              <Box sx={{marginTop: 1}}>
                <Alert severity="warning">This cannot be undone</Alert>
              </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={() => {
              if (Array.isArray(noteToDelete)) {
                handleDeleteNotes(noteToDelete);
              } else {
                handleDeleteNote(noteToDelete);
              }
              setOpenDeleteDialog(false);
              setNoteToDelete(null); // Reset after deletion
            }} 
            color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default MyNotes;
