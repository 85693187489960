import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Footer = () => {
  return (
    <Box sx={{ padding: 3, textAlign: 'center' }}>
      <Typography 
        variant="caption" 
        sx={{ color: theme => theme.palette.neutral[500] }}
      >
        © 2023 Copyright TinHatNotes.com. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;