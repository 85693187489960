import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';

const MyExport = ({ profile, disabled, mydataArray }) => {
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState(`exportedData-${profile}`);
  const [encryptionKey, setEncryptionKey] = useState('');

  const checkedNotesJson = JSON.stringify({ notes: mydataArray });

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setEncryptionKey(''); // Reset the encryption key
    setFileName(`exportedData-${profile}`); // Reset to the default file name
  };  

  const handleExport = () => {
    const encryptedData = CryptoJS.AES.encrypt(checkedNotesJson, encryptionKey).toString();
    const blob = new Blob([encryptedData], { type: 'text/plain' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `${fileName}.txt`; // Append .txt extension
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    handleClose(); // Close the dialog after exporting
  };

  return (
    <div>
      <Button disabled={disabled} onClick={handleOpen}>
        Export Selected
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Export Data</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{paddingTop: 2}}>

            <Typography 
                variant="body1" 
                color="text.secondary" 
            >
                {`You are exporting ${mydataArray.length} ${mydataArray.length === 1 ? 'note' : 'notes'} into file ${fileName}.txt`}
            </Typography>
            <TextField
              autoFocus
              margin="normal"
              label="File Name"
              helperText=""
              type="text"
              fullWidth
              variant="outlined"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
            />
            <TextField
              margin="normal"
              label="Encryption Key"
              type="text"
              fullWidth
              variant="outlined"
              value={encryptionKey}
              onChange={(e) => setEncryptionKey(e.target.value)}
            />
            <Alert severity="warning">Remember the encryption key! It is not stored anywhere at our end.</Alert>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleExport}>
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyExport;