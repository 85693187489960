import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import MyDashboard from './components/MyDashboard';
import { useDatabase } from './context/DatabaseContext';
import CryptoJS from 'crypto-js';
import styled from '@emotion/styled';
import MyDaFuqDialog from './components/MyDaFuqDialog';
import { DecryptedDataContext } from './context/DecryptedDataContext';
import { record } from 'aws-amplify/analytics';

// Styled components
const StyledPaper = styled(Paper)({
    padding: '20px',
    textAlign: 'center',
    width: '100%',
});

const InputRow = styled.div({
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginBottom: '10px',
});

const TextRow = styled.div({
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  marginTop: '10px',
});

const waitMs = 10000;

const HomePage = ({ toggleTheme }) => {
  const [username, setUsername] = useState('');
  const [dKey, setDKey] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const { decryptedData, setDecryptedData } = useContext(DecryptedDataContext);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);

  const [remainingTime, setRemainingTime] = useState(0);
  const [wtfDialog, setWtfDialog] = useState(false);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const db = useDatabase();
  const initialData = {};

  const countdownIntervalRef = useRef(null);

  useEffect(() => {
    const sessionUsername = sessionStorage.getItem('username');

    if (sessionUsername) {
      setIsLoggedIn(true);
      setUsername(sessionUsername);

      record({
        name: 'SessionUserLoggedIn',
        attributes: { username: sessionUsername },
      });
    }
  }, []);

  useEffect(() => {
    if (remainingTime <= 0 && countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
      countdownIntervalRef.current = null;
    }
  }, [remainingTime]);

  const handleCreateUser = async () => {
    // First, validate
    if(username === '' || dKey === '') {
      return;
    }
  
    setIsLoggedIn(false);
  
    // Create a transaction and object store
    try {
      const actualDB = db.db;  // Assuming db is the context from your useDatabase hook
      const tx = actualDB.transaction('Profiles', 'readwrite');
      const store = tx.objectStore('Profiles');
  
      // Check if user already exists
      const existingUser = await store.get(newUsername);
  
      if (existingUser) {
        setSnackbarMessage(`Profile ${newUsername} already exists!`);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        return;
      }
  
      const jsonData = { ...initialData, user: username };
      const jsonString = JSON.stringify(jsonData);
      const encryptedData = CryptoJS.AES.encrypt(jsonString, dKey).toString();
  
      // Prepare the user data
      const userData = {
        username: newUsername,
        encryptedData: encryptedData,
      };
  
      // Add user data to the object store
      await store.add(userData);
      setSnackbarMessage(`Profile ${username} created successfully!`);
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      setIsLoggedIn(true); 
      setShowCreateForm(false);

      record({
        name: 'UsernameCreated',
        attributes: { username: username },
      });

    } catch (error) {
      setSnackbarMessage(`Could not create user: ${error}`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleCancelCreateUser = () => {
    setShowCreateForm(false);
    setUsername('');
    setDKey('');
  }

  const handleFailedLoginAttempt = () => {
    const newAttempts = loginAttempts + 1;
    setLoginAttempts(newAttempts);
    if (newAttempts >= 3) {
      setIsLoginButtonDisabled(true);
      setRemainingTime(waitMs / 1000); // Set initial remaining time
      setSnackbarMessage(`Too many failed attempts. Please wait.`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);

      // Clear any existing interval before starting a new one
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }

      countdownIntervalRef.current = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(countdownIntervalRef.current); // Clear interval when countdown is over
            countdownIntervalRef.current = null;
            setIsLoginButtonDisabled(false);
            setLoginAttempts(0);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      setTimeout(() => {
        clearInterval(countdownIntervalRef.current);
        countdownIntervalRef.current = null;
        setIsLoginButtonDisabled(false);
        setLoginAttempts(0);
      }, waitMs);
    } else {
      setSnackbarMessage(`Key is not valid for the Profile: ${username}`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleOnLogin = async () => {
    if (!db || !username) return;

    const actualDB = db.db;
    const tx = actualDB.transaction('Profiles', 'readonly');
    const store = tx.objectStore('Profiles');
    const userData = await store.get(username);

    if (!userData) {
      setNewUsername(username);
      setDKey('');
      setShowCreateForm(true);

      const completeData = { ...initialData, user: username };
      setDecryptedData(JSON.stringify(completeData));
      return;
    }

    try {
        const decryptedData = CryptoJS.AES.decrypt(userData.encryptedData, dKey).toString(CryptoJS.enc.Utf8);
    
        if (decryptedData) {
          setDecryptedData(decryptedData);
          setIsLoggedIn(true);
          sessionStorage.setItem('username', username);
          setIsLoggedIn(true);
          setLoginAttempts(0); // Reset attempts after successful login

        } else {
            setSnackbarMessage(`Key is not valid for Notebook: ${username}`);
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            handleFailedLoginAttempt();
        }
      } catch (error) {
        setSnackbarMessage(`Decryption failed. Error: ${error}`);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        handleFailedLoginAttempt();
      }
  };

  const handleLogout = () => {
    sessionStorage.removeItem('username');
    setDKey('');
    setIsLoggedIn(false);

    record({
      name: 'SessionUserLoggedOut',
      attributes: { username: username },
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleUpdateDKey = newDKey => {
    setDKey(newDKey);
  };

  const handleWtfDialog = flag => {
    setWtfDialog(flag);
    record({
      name: 'DaFuqDialog',
      attributes: { where: 'before app' },
    });
  };
  
  return (
    <div>
        <Snackbar open={openSnackbar} autoHideDuration={waitMs} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
      {isLoggedIn && decryptedData ? (
        <MyDashboard username={username} dKey={dKey} onLogout={handleLogout} toggleTheme={toggleTheme} updateDKey={handleUpdateDKey} />
      ) : (
        <div>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column', // Align items in a column
            justifyContent: 'center',
            alignItems: 'center', // Center items horizontally
            p: 2,
            mb: 2, // margin bottom
          }}>
            <img 
              src='tfhn.png'
              alt="TinFoilHatNote" 
              style={{ 
                maxWidth: '100%', 
                height: 'auto', 
                borderRadius: '20px', // Apply rounded corners to the image
                cursor: 'pointer', // Change cursor on hover
              }} 
              onClick={() => handleWtfDialog(true)}
            />
            <Typography 
              variant="caption" // Adjust the variant as needed
              sx={{
                fontFamily: 'Arial, sans-serif', // Apply a rounded font-family if available
                mt: 1, // Add some top margin to space it from the image
              }}
            >
              [ <span style={{ fontWeight: 'bold' }}>Tin Hat Notes</span> ]
            </Typography>
          </Box>

          {showCreateForm ? (
            <StyledPaper elevation={3}>
              <p>Notebook does not exist. Create one?</p>
              <InputRow>
                <TextField
                    label="New Profile"
                    variant="outlined"
                    value={newUsername}
                    onChange={(e) => setNewUsername(e.target.value)}
                />
                <TextField
                    label="AES Key (not stored!)"
                    variant="outlined"
                    value={dKey}
                    onChange={(e) => setDKey(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={handleCreateUser}>
                    Create
                </Button>
                <Button variant="contained" color="primary" onClick={handleCancelCreateUser}>
                    Cancel
                </Button>
              </InputRow>
            </StyledPaper>
          ) : (
            <StyledPaper elevation={3}>
                <InputRow>
                    <TextField
                        label="Notebook"
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        label="AES Key"
                        variant="outlined"
                        type="password"
                        value={dKey}
                        onChange={(e) => setDKey(e.target.value)}
                    />
                    <Button variant="contained" color="primary" onClick={handleOnLogin} disabled={isLoginButtonDisabled}>
                        Decrypt and Enter
                    </Button>
                    
                </InputRow>
                <TextRow>
                  {isLoginButtonDisabled && <Typography variant="caption">Too many failed attempts. Please wait {remainingTime} seconds</Typography>}
                </TextRow>
            </StyledPaper>
          )}
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 4,
            }}>
                <img 
                src='dafuq.png'
                alt="dafuq" 
                style={{ 
                    maxWidth: '100%', 
                    height: 'auto', 
                    cursor: 'pointer', // Change cursor on hover
                }} 
                onClick={() => handleWtfDialog(true)}
                />
            </Box>
        </div>
      )}

      <MyDaFuqDialog open={wtfDialog} onClose={() => handleWtfDialog(false)} />
    </div>
  );
};

export default HomePage;
