import React, { useState, useEffect, useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const MyFilteredNotes = ({ notes, onFilteredNotesChange }) => {
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // Extract unique labels from notes
  const allLabels = useMemo(() => {
    const labels = new Set();
    if (Array.isArray(notes)) {
      notes.forEach(note => {
        if (note.labels && Array.isArray(note.labels)) {
          note.labels.forEach(label => labels.add(label));
        }
      });
    }
    return Array.from(labels);
  }, [notes]);

  // Filter notes based on selected labels
  const filteredNotes = useMemo(() => {
    if (selectedLabels.length === 0) return notes;
    return notes.filter(note => 
      note.labels && selectedLabels.every(label => note.labels.includes(label))
    );
  }, [notes, selectedLabels]);

  // Call the callback function whenever filteredNotes changes
  useEffect(() => {
    if (onFilteredNotesChange) {
      onFilteredNotesChange(filteredNotes);
    }
  }, [filteredNotes, onFilteredNotesChange]);

  const handleLabelSelect = (_, newValue) => {
    setSelectedLabels(newValue);
  };

  return (
    <Box 
      sx={{
        flexGrow: 1, // Make the Box grow to fill up the available space
        mt: theme => theme.spacing(2),
        mb: theme => theme.spacing(2),
        minWidth: 300,
      }}
    >
      <Autocomplete
        multiple
        options={allLabels}
        value={selectedLabels}
        onChange={handleLabelSelect}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        renderInput={(params) => <TextField {...params} label="Filter by label" />}
      />
      {/* Render filtered notes or some other elements based on filteredNotes */}
    </Box>
  );
};

export default MyFilteredNotes;