import React, { useState } from 'react';
import { Card, CardContent, CardActions, CardHeader, Typography, Button, Grid } from '@mui/material';
import MyDaFuqDialog from './/MyDaFuqDialog';
import { record } from 'aws-amplify/analytics';

const MyToolbox = ({ dbSize, onLoadExample }) => {
  const [wtfDialog, setWtfDialog] = useState(false);
  // You can add more cards to this array as needed

  const bytesToMB = (bytes) => {
    const mb = bytes / (1024 * 1024);
    return mb.toFixed(2); // Returns the value rounded to 2 decimal places
  };
  
  const cards = [
    {
      title: 'About',
      description: 'Gives you basic info about the project.',
      actions: [
        { label: 'Show', onClick: () => {handleWtfDialog(true)} },
        // ... more actions
      ],
    },
    {
      title: 'Show me how',
      description: 'With loading sample data, you will quickly learn how to use the app.',
      actions: [
        { label: 'Load Sample Data', onClick: onLoadExample },
        // ... more actions
      ],
    },
    {
      title: 'Encryption Key Management',
      description: 'Comming soon',
      actions: [
        { label: 'Change Key', onClick: () => {} },
        // ... more actions
      ],
    },
    {
      title: 'Current DB size',
      description: `Approx. ${bytesToMB(dbSize)}Mb (${dbSize} bytes)`,
      actions: [],
    },
    // ... add more cards if needed
  ];

  const handleWtfDialog = flag => {
    setWtfDialog(flag);
    record({
      name: 'DaFuqDialog',
      attributes: { where: 'in app' },
    });
  };

  return (
    <>
    <Grid container spacing={2}>
      {cards.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card>
            <CardHeader title={card.title} />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {card.description}
              </Typography>
            </CardContent>
            <CardActions>
              {card.actions.map((action, idx) => (
                <Button size="small" onClick={action.onClick} key={idx}>
                  {action.label}
                </Button>
              ))}
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
    <MyDaFuqDialog open={wtfDialog} onClose={() => handleWtfDialog(false)} />
    </>
  );
};

export default MyToolbox;
