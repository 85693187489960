import React, { createContext, useState } from 'react';

// Create a context
export const DecryptedDataContext = createContext();

// Create a Provider component
export const DecryptedDataProvider = ({ children }) => {
  const [decryptedData, setDecryptedData] = useState(null);
  
  return (
    <DecryptedDataContext.Provider value={{ decryptedData, setDecryptedData }}>
      {children}
    </DecryptedDataContext.Provider>
  );
};