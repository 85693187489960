import React, { useState, useEffect } from 'react';
import { Button, IconButton, Dialog, DialogActions, DialogContent, TextField, DialogTitle, Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useRef } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import CryptoJS from 'crypto-js';
import sampleContent from './sampleContent123.txt';
import { record } from 'aws-amplify/analytics';

const MyImport = ({ onSuccess, onError, loadExample=false, onSuccessExampleLoaded }) => {
  const [open, setOpen] = useState(false);
  const [decryptionKey, setDecryptionKey] = useState('');
  const [fileContent, setFileContent] = useState('');
  const [fileName, setFileName] = useState('');
  const obfuscatedKey = [49, 50, 51];

  const deriveKey = () => {
    return String.fromCharCode(...obfuscatedKey);
  };

  useEffect(() => {
    if (loadExample) {
      fetch(sampleContent)
        .then(response => response.text())
        .then(text => {
          doDecryptContent(text, deriveKey());
          onSuccessExampleLoaded();
        });
        record({
          name: 'SampleDataLoaded',
          attributes: { open: true },
        });
    }
  }, [loadExample]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDecryptionKey(''); /* Reset the decryption key */
    setFileContent('');   /* Optionally, also reset the file content if needed */
  };

  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const doDecryptContent = (encryptedFileContent, userDKey) => {
    try {
      const decryptedData = CryptoJS.AES.decrypt(encryptedFileContent, userDKey).toString(CryptoJS.enc.Utf8);
      const parsedObj = JSON.parse(decryptedData);
      if (parsedObj && parsedObj.notes) {
        onSuccess(parsedObj.notes);
      }
    } catch (error) {
      onError();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name); // Set the file name
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileContent(e.target.result);
      };
      reader.readAsText(file);
    }
  };

  const handleDecrypt = () => {
    doDecryptContent(fileContent, decryptionKey);
    setOpen(false);
  };

  return (
        <div>

            <IconButton
              aria-label="import"
              size="large"
              onClick={handleClickOpen}
            >
              <CloudDownloadIcon sx={{ verticalAlign: 'middle', color: 'primary.main' }} />

            </IconButton>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Import Data</DialogTitle>
            <DialogContent>
              <Stack spacing={2} mt={2}>
                <Button 
                  variant="outlined" 
                  startIcon={<UploadFileIcon />} 
                  onClick={handleFileButtonClick}
                >
                  Select File
                </Button>
                <input
                  ref={fileInputRef}
                  type="file"
                  onChange={handleFileChange}
                  hidden
                />
                <Typography 
                    variant="body1" 
                    color="text.secondary" 
                >
                    {fileName || 'No file selected'}
                </Typography>

                <Alert severity="warning">The decryption Key is different from your current profile key.
                  It is the key used to encrypt the file content.</Alert>
                <TextField
                  autoFocus
                  label="Decryption Key"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={decryptionKey}
                  onChange={(e) => setDecryptionKey(e.target.value)}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleDecrypt}>Decrypt and Import</Button>
            </DialogActions>
          </Dialog>
        </div>
  );
};

export default MyImport;