import React, { useState } from 'react';
import { Box, IconButton, Button, Autocomplete, TextField, Chip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

const MyLabels = ({ noteLabels, labels, onAddLabelToNote, onLabelDelete }) => {
  const [showLabelInput, setShowLabelInput] = useState(false);
  const [inputValue, setInputValue] = useState(''); // New state for the input value of Autocomplete
  const [newLabelText, setNewLabelText] = useState('');

  const handleAddLabel = () => {
    const trimmedLabelText = inputValue.trim(); // Use inputValue instead of newLabelText
    if (trimmedLabelText) {
      onAddLabelToNote(trimmedLabelText);
      setNewLabelText(''); // Clear the Autocomplete selected value
      setInputValue(''); // Clear the Autocomplete input
      setShowLabelInput(false);
    }
  };

  return (
    <Box>
      <div style={{ marginTop: '1px', display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
      {noteLabels && noteLabels.map((label) => (
        <Chip
          key={label}
          label={label}
          onDelete={() => onLabelDelete(label)}
          variant="outlined"
          color="primary"
          style={{ margin: '2px' }}
        />
      ))}

      {!showLabelInput && (
        <IconButton onClick={() => setShowLabelInput(true)}>
          <AddCircleOutlineIcon />
        </IconButton>
      )}

      {showLabelInput && (
        <Box sx={{ p: 0 }}>
          <Autocomplete
            options={labels}
            value={newLabelText}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue); // Update input value
            }}
            onChange={(event, newValue) => {
              setNewLabelText(newValue); // Update selected value
            }}
            freeSolo // Allows entering text not in the options
            renderInput={(params) => (
              <TextField {...params} label="Add a label" size="small" />
            )}
            sx={{ mb: 1 }} // Margin bottom for spacing
          />
          <Button variant="contained" onClick={handleAddLabel}>
            Add Label
          </Button>
          <IconButton onClick={() => setShowLabelInput(false)}>
            <CancelIcon />
          </IconButton>
        </Box>
      )}
      </div>
    </Box>
  );
};

export default MyLabels;
