import React, { createContext, useState, useEffect, useContext } from 'react';
import { openDB } from 'idb';

// Create a context
export const DatabaseContext = createContext(null);

// Create a provider component
export const DatabaseProvider = ({ children }) => {
  const [db, setDb] = useState(null);
  const [password, setPassword] = useState(null);  // Added password state

  useEffect(() => {
    const initDB = async () => {
      const database = await openDB('TFHNAppDB', 1, {
        upgrade(db) {
            db.createObjectStore('Profiles', { keyPath: 'username' });
        },
      });
      setDb(database);
    };

    initDB();
  }, []);

  const getObjectSize = (object) => {
    const objectString = JSON.stringify(object);
    return new Blob([objectString]).size;
  };

  const getDBSize = async () => {
    if (!db) {
      console.error('Database not initialized');
      return 0;
    }
  
    let totalSize = 0;
  
    const tx = db.transaction('Profiles', 'readonly');
    const store = tx.objectStore('Profiles');
  
    await store.openCursor().then(function cursorIterate(cursor) {
      if (!cursor) return;
  
      const recordSize = getObjectSize(cursor.value);
      totalSize += recordSize;
  
      return cursor.continue().then(cursorIterate);
    });
  
    console.log(`Total DB size: ${totalSize} bytes`);
    return totalSize;
  };  

  return (
    <DatabaseContext.Provider value={{ db, password, setPassword, getDBSize }}>  {/* Expose password and setPassword */}
      {children}
    </DatabaseContext.Provider>
  );
};

// Custom hook to use the database object and password
export const useDatabase = () => {
    const context = useContext(DatabaseContext);
  
    if (!context) {
      throw new Error("Database has not been initialized. Make sure you're accessing it within the DatabaseProvider.");
    }
  
    const { db, password, setPassword, getDBSize } = context;  // Destructure from context
  
    return { db, password, setPassword, getDBSize };  // Return as an object
  };