
export const truncateString = (str, maxWords, maxLength = 24) => {
    // Check if str is undefined or null
    if (!str) {
        return ''; // Return an empty string if str is not a valid string
    }
      // Split the string by spaces to get the words
      const words = str.split(' ');
      let truncated = '';
      let wordCount = 0;
    
      for (let word of words) {
        // Check if adding the next word would exceed the maxLength
        if ((truncated.length + word.length + (wordCount > 0 ? 1 : 0)) > maxLength) {
          if (wordCount === 0) {
            truncated = word.substring(0, maxLength - 2); // Account for ".."
            break;
          }
          break;
        }
        truncated += (wordCount > 0 ? ' ' : '') + word;
        wordCount++;
        if (wordCount === maxWords) {
          break;
        }
      }
    
      if (truncated.length > maxLength || wordCount === 0) {
        truncated = truncated.substring(0, maxLength - 2);
      }
    
      if (truncated.length < str.length) {
        truncated += '..';
      }
      return truncated;
};