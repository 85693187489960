import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const MyDaFuqDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>About</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <strong>What&apos;s TinHatNotes?</strong>
          <p>TinHatNotes is your go-to web app for storing personal notes securely, right in your browser. Think of it like a super-secure version of browser bookmarks, but for your notes.</p>

          <strong>Key Features:</strong>
          <ul>
            <li><strong>No Registration Required:</strong> Start using TinHatNotes without creating an account. Just create a notebook and start taking notes securely.</li>
            <li><strong>Local Encryption:</strong> Your notes are encrypted and stored locally in your browser’s storage (Indexed DB). Only <em>you</em> know the encryption key.</li>
            <li><strong>Data Control:</strong> You can export and import your notes whenever you want. Your data stays with you, not in the cloud.</li>
            <li><strong>Free to Use:</strong> No hidden charges, no premium tiers. TinHatNotes is completely free.</li>
            <li><strong>Privacy First:</strong> Unlike Apple Notes or Google Keep, we don’t trust companies that might use your data for advertising. Your notes are yours alone.</li>
            <li><strong>Secure:</strong> We use AES-256 encryption to keep your notes safe. If you lose your key, even we can’t help you recover your notes – we never know your key.</li>
          </ul>

          <strong>Why TinHatNotes?</strong>
          <ul>
            <li><strong>No Cloud Storage:</strong> Your data isn’t stored in the cloud where it could potentially be accessed or mined.</li>
            <li><strong>No Data Mining:</strong> We don't sell your data to advertisers or any third parties.</li>
            <li><strong>User Control:</strong> You manage your encryption, decryption, and data export/import.</li>
            <li><strong>Multiple Notebooks:</strong> Create as many notebooks as you need, each with its own encryption key. Export/import notes with ease, and keep them on thumb drives, email, or anywhere you like.</li>
          </ul>

          <strong>Limitations:</strong>
          <p>Browser-Specific Storage: Your encrypted notes are tied to the browser on your specific device. Want to switch browsers or devices? Export your notes and import them into the new browser.</p>
          <p>Experimental: This project is experimental. Use it at your own risk. We’re not responsible for any lost data or other issues.</p>

          <strong>Disclaimer:</strong>
          <p>TinHatNotes is an experimental project. We don’t take any responsibility for any damage, lost data, or other issues that might arise from using the app.</p>

          <strong>Fun Fact:</strong>
          <p>TinHatNotes was coded almost entirely with Chat GPT 4. Yep, this text was created with Chat GPT too! The project is proudly deployed on AWS.</p>

          <p>Enjoy the freedom and security of TinHatNotes, and remember – keep your encryption key safe!</p>

          <strong>Quick Tip:</strong>
          <p>Create a notebook, go to "My Toolbox", and then click "Show me how" to load sample data.
          With sample data loaded, you'll quickly learn how to use the app.</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MyDaFuqDialog;
