import React, { useState, useEffect } from 'react';
import {capitalize} from 'lodash';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';

const MyAppBar = props => {
  const { profile, toggleTheme, onLogout} = props;
  const [user, setUser] = useState('');

  useEffect(() => {
    setUser(profile.user);
  }, [profile]);

  return (
    <Box sx={{ flexGrow: 1 }}>

      <AppBar position="static">
        <Toolbar>
          <img 
            src='tfhn.png' 
            alt="Logo" 
            style={{ 
              marginRight: '20px', // adds some space after the logo
              height: '40px', // adjust the height as needed
            }} 
          />
          <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" component="span">
                {`Notebook`}
              </Typography>
              <Typography variant="h6" component="span" style={{ fontWeight: 'bold', marginLeft: '4px', marginRight: '1px' }}>
                {user}
              </Typography>
            </div>
            <Typography variant="caption" component="div">
              <span>{"Welcome to "}</span>
              <span style={{ fontWeight: 'bold' }}>{`TinHatNotes`}</span>
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '16px 0' }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={localStorage.getItem('theme') === 'dark'}
                        onChange={toggleTheme}
                    />
                }
                label={capitalize(localStorage.getItem('theme'))}
            />
        </div>
        <Divider 
            orientation="vertical" 
            flexItem 
            sx={{ mx: 2, my: 1, height: 'auto', borderColor: theme => theme.palette.neutral[500] }}
        />
        <Button variant="contained" color="primary" onClick={onLogout}>Exit</Button>

        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default MyAppBar;
