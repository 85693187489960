import React, { useState, useEffect } from 'react';
import { DatabaseProvider } from './context/DatabaseContext';
import { DecryptedDataProvider } from './context/DecryptedDataContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import dTheme from '../src/theme/vs-theme-dark';
import ltTheme from '../src/theme/vs-theme-light';

import HomePage from './HomePage';
import Footer from './Footer';

import { Amplify } from 'aws-amplify';
import { record } from 'aws-amplify/analytics';
import amplifyconfig from './amplifyconfiguration.json';

Amplify.configure(amplifyconfig);

/*
const ltTheme = createTheme({
  palette: {
    mode: 'light',
  },
});
const dTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
*/

function App() {
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'dark';
    setTheme(savedTheme);
    record({
      name: 'App Loaded',
      attributes: { savedTheme: savedTheme },
    });
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  const appliedTheme = createTheme(theme === 'light' ? ltTheme : dTheme);

  return (
    <ThemeProvider theme={appliedTheme}>
      <CssBaseline />
      <DecryptedDataProvider>
        <DatabaseProvider>
          <HomePage toggleTheme={toggleTheme} />
          <Footer />
        </DatabaseProvider>
      </DecryptedDataProvider>
    </ThemeProvider>
  );
}

export default App;