import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import dayjs from 'dayjs';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReactQuill from 'react-quill';
import Delta from 'quill-delta';
import 'react-quill/dist/quill.snow.css';
import { qToolbarOptions } from '../theme/quillToolbarOptions';
import '../theme/ReactQuillStyles.css';
import MyLabels from './MyLabels';
import relativeTime from 'dayjs/plugin/relativeTime';
import { truncateString } from '../utils/noteUtils';

dayjs.extend(relativeTime);

const MyNote = ({ note, labels, isCheckEnabled, isChecked, isExpanded, onCheckUpdated, onSave, onCancel, onDelete, onAddLabelToNote, onLabelDelete, isGridView }) => {
  const [editText, setEditText] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [chipVisible, setChipVisible] = useState(true);
  const [isImportedColor, setIsImportedColor] = useState(note.imported);
  const [noteChecked, setNoteChecked] = useState(isChecked || false);
  const originalTextRef = useRef(note.text);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const textRef = useRef(null);
  const [isOverflowTop, setIsOverflowTop] = useState(false);
  const [isOverflowBottom, setIsOverflowBottom] = useState(false);
  const quillRef = useRef(null);
  const [expanded, setExpanded] = useState(isExpanded || false);
  const [expandedHoriz, setExpandedHoriz] = useState(false);
  const [animateBorder, setAnimateBorder] = useState(false);

  const setupClipboardMatcher = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor(); // Accessing the Quill editor instance

      // Add a custom clipboard matcher
      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        const newDelta = new Delta();
        delta.ops.forEach(op => {
          if (!(op.insert && typeof op.insert === 'object' && op.insert.image)) {
            newDelta.push(op); // Add non-image ops to the new Delta
          }
        });
        return newDelta;
      });
    }
  };

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    setupClipboardMatcher();
  }, [quillRef.current]);

  const cardStyles = {
    width: expandedHoriz ? '100%' : (isGridView ? '400px' : '100%'), // Respect isGridView unless expandedHoriz is true
    maxWidth: '100%', // Ensure it doesn't overflow the container
    border: '1px solid', // Default border style
    borderColor: animateBorder ? 'primary.main' : 'divider', // Apply animation border color when active
    animation: animateBorder ? 'fadeBorder 2.0 s ease-in-out' : 'none', // Trigger animation
  };

  useEffect(() => {
    setIsImportedColor(note.imported);
  }, [note.imported]);

  useEffect(() => {
    originalTextRef.current = note.text;
    setEditText(note.text);
  }, [note]);

  useEffect(() => {
    setNoteChecked(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = (event) => {
    setNoteChecked(event.target.checked);
    if (onCheckUpdated) {
      onCheckUpdated(note.id, event.target.checked);
    }
  };

  const checkOverflow = () => {
    const element = textRef.current;
    if (element) {
      const isTopOverflowing = element.scrollTop > 0;
      const isBottomOverflowing = element.scrollHeight > element.clientHeight + element.scrollTop;
      setIsOverflowTop(isTopOverflowing);
      setIsOverflowBottom(isBottomOverflowing);
    }
  };

  useLayoutEffect(() => {
    const textElement = textRef.current;
    if (textElement) {
      checkOverflow();
      textElement.addEventListener('scroll', checkOverflow);
      return () => textElement.removeEventListener('scroll', checkOverflow);
    }
  }, [note.text, expanded]);

  const handleSave = (e) => {
    if (!editText.trim()) {
      return;
    }
    onSave(note.id, editText, true);
    originalTextRef.current = editText;
    setIsEdit(false);
    setShowConfirmDialog(false);
  };

  const handleEdit = () => {
    setEditText(note.text);
    setIsEdit(true);
    setExpanded(true);
  };

  const handleCancel = () => {
    if (editText !== originalTextRef.current) {
      setShowConfirmDialog(true); // Show confirmation if there are unsaved changes
    } else {
      setIsEdit(false);
    }
  };

  const discardChanges = () => {
    setEditText(originalTextRef.current); // Revert to original text
    setShowConfirmDialog(false);
    setIsEdit(false);
  };

  const keepEditing = () => {
    setShowConfirmDialog(false);
  };

  const handleAddLabelToNote = (text) => {
    onAddLabelToNote(note.id, text);
  };

  const handleDeleteLabel = (labelToDelete) => {
    const updatedLabels = note.labels.filter(label => label !== labelToDelete);
    onLabelDelete(note.id, updatedLabels);
  };

  const formatUpdated = () => {
    const dateMilliseconds = parseInt(note.updated, 10);
    const date = dayjs(dateMilliseconds);
    return date.format('MMMM D, YYYY');
  };

  const handleScroll = (direction) => {
    const textElement = textRef.current;
    if (!textElement) return;

    if (direction === 'top') {
      textElement.scrollTop = 0;
    } else if (direction === 'bottom') {
      textElement.scrollTop = textElement.scrollHeight - textElement.clientHeight;
    }
  };

  const handleChipFadeAndSaveClick = () => {
    setChipVisible(false);
    setTimeout(() => {
      onSave(note.id, editText, false);
    }, 500);
  };

  const handleAvatarClick = () => {
    setIsImportedColor(false);
    setTimeout(() => {
      onSave(note.id, editText, false);
    }, 500);
  };

  const extractText = html => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleExpandHorizClick = () => {
    const newExpandedHoriz = !expandedHoriz;
  
    setExpandedHoriz(newExpandedHoriz);
    
    if (newExpandedHoriz) {
      setExpanded(true); // Set expanded to true if expandedHoriz is true
    } else {
      isExpanded ? setExpanded(true) : setExpanded(false);
    }

    setAnimateBorder(true);
    setTimeout(() => {
      setAnimateBorder(false);
    }, 500); // Animation duration should match the CSS animation duration
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleCancel}>
        <Card sx={cardStyles}>
          <CardHeader
            avatar={
              <Avatar 
                onClick={handleAvatarClick}
                sx={{ 
                  bgcolor: isImportedColor ? 'secondary.main' : theme => theme.palette.neutral[500], 
                  border: '1px solid #64B6F7',
                  transition: 'background-color 500ms ease-in-out',
                  cursor: 'pointer'
                }} 
                aria-label="note"
              >
                {isImportedColor ? "I" : "N"}
              </Avatar>
            }
            action={
              <>
                <IconButton aria-label="expand" onClick={handleExpandClick}>
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <IconButton aria-label="expand-horiz" onClick={handleExpandHorizClick}>
                  {expandedHoriz ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                <IconButton aria-label="edit" onClick={() => handleEdit(note.text)}>
                  <EditIcon color={isEdit ? "primary" : "default"} />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => onDelete(note.id)}>
                  <DeleteIcon />
                </IconButton>
              </>
            }
            title={truncateString(extractText(note.text), 5)}
            subheader={formatUpdated()}
          />

          {expanded && (
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {isCheckEnabled && (
                  <Checkbox
                    checked={noteChecked}
                    onChange={handleCheckboxChange} 
                  />
                )}
              </Box>
              <Box sx={{ position: 'relative' }}>
                {!isEdit && isOverflowTop && (
                  <IconButton 
                    onClick={() => handleScroll('top')}
                    sx={{ 
                      position: 'absolute', 
                      top: -30, 
                      left: '50%', 
                      transform: 'translateX(-50%)', 
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      width: 40, // Adjust the width to make it square
                      height: 25, // Adjust the height to make it square
                      borderRadius: 5, // Make the corners square
                      padding: 0, // Remove padding to avoid overlap with content
                     }}
                  >
                    <MoreHorizIcon color="primary" />
                  </IconButton>
                )}

                <Box sx={{ width: '100%', p: 2, my: 1, borderRadius: '4px', maxHeight: '500px', overflowY: 'auto', backgroundColor: theme => theme.palette.cardBody.main }} ref={textRef}>
                  {isEdit ? (
                    <ReactQuill 
                      ref={quillRef}
                      theme="snow"
                      scrollingContainer="body"
                      value={editText}
                      onChange={setEditText}
                      modules={{ 
                        toolbar: qToolbarOptions,
                        clipboard: {
                          matchVisual: false
                        } 
                      }}
                      className={`custom-quill-${localStorage.getItem('theme')}`}
                    />
                  ) : (
                    <div 
                      dangerouslySetInnerHTML={{ __html: note.text }} 
                      style={{ whiteSpace: 'pre-wrap' }}
                    />
                  )}
                </Box>

                {!isEdit && isOverflowBottom && (
                  <IconButton 
                    onClick={() => handleScroll('bottom')}
                    sx={{ 
                      position: 'absolute', 
                      bottom: -30, 
                      left: '50%', 
                      transform: 'translateX(-50%)', 
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      width: 40, // Adjust the width to make it square
                      height: 25, // Adjust the height to make it square
                      borderRadius: 5, // Make the corners square
                      padding: 0, // Remove padding to avoid overlap with content
                     }}
                  >
                    <MoreHorizIcon color="primary" />
                  </IconButton>
                )}
              </Box>
              <br />
              <div>
                <MyLabels
                  noteLabels={note.labels}
                  labels={labels}
                  onAddLabelToNote={handleAddLabelToNote}
                  onLabelDelete={handleDeleteLabel}
                />
              </div>
            </CardContent>
          )}

          <CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
            {isEdit && (
              <ButtonGroup variant="contained">
                <Button onClick={handleSave} variant="contained" color="primary" disabled={!editText.trim()}>
                  Save
                </Button>
                <Button onClick={handleCancel} variant="contained" color="primary">
                  Cancel
                </Button>
              </ButtonGroup>
            )}
          </CardActions>
        </Card>
      </ClickAwayListener>

      <Dialog
        open={showConfirmDialog}
        onClose={keepEditing}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have unsaved changes. Would you like to save them?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={discardChanges} variant="contained" color="primary">
            Ignore
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyNote;
