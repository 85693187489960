import React, { useState, useEffect, useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DecryptedDataContext } from '../context/DecryptedDataContext';
import { useDatabase } from '../context/DatabaseContext';

import MyNotes from './MyNotes';
import MyAppBar from './MyAppBar';
import MyToolbox from './MyToolbox';
import GoogleDriveAuthComponent from './GoogleDriveAuthComponent';

import { record } from 'aws-amplify/analytics';

const MyDashboard = props => {
  const { username, dKey, onLogout, toggleTheme } = props;
  const [dashData, setDashData] = useState({});
  const { decryptedData, setDecryptedData } = useContext(DecryptedDataContext);
  const {db, getDBSize} = useDatabase();

  const [expandedStates, setExpandedStates] = useState([true, false]);
  const [notesLabel, setNotesLabel] = useState('0');
  const [loadExample, setLoadExample] = useState(false);
  const [dbSize, setDbSize] = useState(0);

  useEffect(() => {
    const parsedData = JSON.parse(decryptedData);
    setDashData(parsedData);
  }, [decryptedData]);

  useEffect(() => {
    const fetchDBSize = async () => {
      if (db) {  // Check if db is initialized
        const size = await getDBSize();
        setDbSize(size);
      }
    };
    fetchDBSize();
  }, [db]);
  

  const handleAccordionToggle = (index) => {
    const newStates = [...expandedStates];
    newStates[index] = !newStates[index];
    setExpandedStates(newStates);
  };

  const handleUpdate = async (encryptedData) => {
    try {
      if (!db) {
        console.error("Database not initialized");
        return;
      }
  
      const actualDB = db;  // db is already the database instance, no need for db.db
      const tx = actualDB.transaction('Profiles', 'readwrite');
      const store = tx.objectStore('Profiles');
  
      // Retrieve existing data
      const existingUser = await store.get(username);
  
      if (!existingUser) {
        console.error("User not found");
        return;
      }
  
      // Update the encrypted data for the existing user
      const updatedUserData = {
        ...existingUser,
        encryptedData: encryptedData
      };
  
      // Save the updated data back to the object store
      await store.put(updatedUserData);
  
      console.log('Successfully updated the data in IndexedDB');
    } catch (error) {
      console.error('There was a problem updating the data:', error);
    }
  };

  const handleNotesLoaded = (newLabel) => {
    if (newLabel < 1) {
      setExpandedStates([expandedStates[1], true]); // Expand My Toolbox pane
    }
    setNotesLabel(newLabel);
    record({
      name: 'NotesLoaded',
      attributes: { notebookLoaded: username },
    });
  };

  const handleLoadExample = () => {
    setLoadExample(true);
  };

  const handleSyncLoaded = (decryptedData) => {
    setDecryptedData(decryptedData);
  };

  return dashData && (
    <div>
      <MyAppBar profile={dashData} onLogout={onLogout} toggleTheme={toggleTheme} />
      <Accordion expanded={expandedStates[0]} onChange={() => handleAccordionToggle(0)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{`My Notes (${notesLabel})`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MyNotes onUpdate={handleUpdate} onLoad={handleNotesLoaded} dKey={dKey} loadExample={loadExample} onExampleLoaded={() => setLoadExample(false)} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expandedStates[1]} onChange={() => handleAccordionToggle(1)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{`My Toolbox`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MyToolbox onLoadExample={handleLoadExample} dbSize={dbSize} />
        </AccordionDetails>
      </Accordion>
      {/* <Accordion expanded={expandedStates[2]} onChange={() => handleAccordionToggle(2)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{`Cloud Sync (Experimental)`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GoogleDriveAuthComponent decryptedData={decryptedData} notebookName={username} dKey={dKey} onAuthSuccess={(token) => console.log('Authenticated with token:', token)} onSyncLoaded={handleSyncLoaded} />
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
};

export default MyDashboard;
